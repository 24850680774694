import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => {
  const keyRef = 'scaleway';
  const provider = 'Scaleway';

  return (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="snapshots"
      title="Scaleway Server and Volume Backup"
      description="Automate your Scaleway Server and Volume & Volume backups. Set a schedule, a storage and let it work in peace."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">Scaleway server & volume</span> backups</>)}
      subTitle={(
        <>Automatically back up your all your Servers and Volumes.<br/>Recover your data in minutes, and focus on building incredible things.
        </>)}
      checkMarks={['Server and Volume backup']}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename="brands/icons/scaleway-ico.png" alt="Scaleway backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Scaleway Backup" urlAtrr={{ sb_source: "website", sb_term: "scaleway-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename={`simplebackups-${keyRef}-snapshot-hero.png`} alt={`SimpleBackups ${provider} snapshot`}/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Instance & volume backups",
                title: (<>Flexible & unrestricted scheduling for Scaleway</>),
                description: (
                  <>
                    <p>
                      Unlock flexible scheduling & retention for your Scaleway backups.<br/>

                      <Ui.FeatureChecks items={[
                        'Control of when your backup will run', 'Unrestricted retention', 'Unlimited backup intervals']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-scheduling'
                  alt={`${provider} Backup custom scheduling`}
                  style={{maxWidth: 450}}
                />)
              },
              {
                title: (<>Server and Volume & Volume backup</>),
                description: (
                  <>
                    <p>
                      Use your API Token to connect your Scaleway account(s).<br/>
                      Select the Servers and Volumes you want to backup and let their backup run on schedule.
                      <Ui.FeatureChecks items={['Server & Volume Backup', 'Unlimited connected accounts']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename={`simplebackups-app-backup-lifecycle.png`}
                  alt={`${provider} backup `}
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Sleep better, trust your backups</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups is fully integrated with Scaleway, meaning all is configurable from an easy-to-use UI. No maintenance required.</p>
            <p className="py-5 text-lg font-bold">Using Scaleway Server and Volume? No problem!</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
              <li><i className="far fa-cloud text-green mr-2"></i>Scaleway Server Backup</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Scaleway Volume Backup</li>
            </ul>

            <Ui.Typography tag="h3" className="text-2xl text-center mt-16 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["snapshot"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Scaleway Server and Volume & Volume Backups?</>}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['scaleway_what_is_snapshot', 'how_snapshot', 'use_for_client_2', 'snapshot_where', 'use_for_other_things']}/>

  </Layout.Layout>
)}

export default ContentPage
